<template>
  <div class="patients-list">
    <div class="d-flex flex-row justify-content-between header">
      <div class="d-flex flex-row align-items-end">
        <p class="title">Meus pacientes</p>
        <p class="quantity">{{ total }} pacientes cadastrados</p>
      </div>

      <div>
        <span
          v-show-feature="'unify-patient'"
          v-if="userHasPermission('FpPac3') && userHasPermission('FpPac4')"
        >
          <toggle-button
            class="mr-3"
            v-model="activePatientSelection"
            :color="{ checked: '#00C773' }"
          />

          <b-button
            variant="outline-primary"
            class="mr-3"
            @click="openUnifyPatientModal"
            :disabled="!activePatientSelection || patientSelecteds.length < 2"
            v-b-tooltip.hover
            :title="
              activePatientSelection
                ? 'Selecione pela caixa de seleção dois pacientes ou mais para unificar paciente'
                : 'Indisponível, habilite o switch ao lado'
            "
          >
            {{ unificateButtonText }}
          </b-button>
        </span>

        <router-link v-can="'FpPac2'" to="/pacientes/novo">
          <b-button variant="primary">Novo paciente</b-button>
        </router-link>
      </div>
    </div>

    <div class="list-container">
      <div class="list-header d-flex flex-row justify-content-between">
        <div class="input-wrapper">
          <div class="input-container mr-3">
            <Search class="input-icon" />
            <b-input
              autocomplete="off"
              :disabled="disableSearchInput"
              type="text"
              v-model="search"
              class="form-control"
              @input="debounceGetPatients"
              @keyup="debounceLoadOnEmpty"
              placeholder="Pesquisar paciente"
              maxlength="80"
            />
          </div>

          <b-button @click="openFilterModal" class="mr-3 filter__container">
            <Icon tooltip="Filtro de pacientes">
              <FilterBar />
            </Icon>
            <div v-if="patientStatus" class="unread-indicator"></div>
          </b-button>

          <span v-if="periodSelectedString" class="mr-2">
            {{ periodSelectedString }}
          </span>

          <Loading
            :class="{ icon: true, reload: true, loading: isLoading }"
            @click="() => !isLoading && loadPatients(true)"
          />
        </div>

        <ul class="filter-letter__container">
          <li
            v-for="letter of letters"
            :class="{ active: letter == selectedLetter && search === '' }"
            @click="selectLetter(letter)"
            :key="letter"
          >
            {{ letter }}
            <span
              v-if="letter === selectedLetter && search === ''"
              class="removeFilter"
            >
              <Close class="close" />
            </span>
          </li>
        </ul>
      </div>

      <div class="pagination-position pt-4 pb-0">
        <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="limit"
            first-number
            last-number
            size="sm"
        />
      </div>

      <div class="list-body">
        <table class="table" v-if="patients?.length">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Nº do prontuário</th>
              <th>Celular</th>
              <th>Perfil</th>
              <th v-show="activePatientSelection"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(patient, i) of patients" :key="i">
              <td>
                <div
                  class="d-flex align-items-center"
                  :class="{ inactive: !!patient.delete_reason }"
                >
                  <Avatar size="40" :src="patient.picture" class="mr-3" />
                  <div>
                    <p>
                      {{ patient.name }}
                    </p>
                    <p class="inactive-label" v-if="patient.delete_reason">
                      Inativo
                    </p>
                    <div class="row ml2">
                      <div>
                        {{ getBirthdayDate(patient.birthday) }}
                      </div>
                      <div class="divisor">|</div>
                      <div>
                        {{ getAge(patient?.birthday) }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-items-center"
                  :class="{ inactive: !!patient.delete_reason }"
                >
                  {{ patient.medical_record_number || '-' }}
                </div>
              </td>
              <td>
                <a
                  class="whatsapp-link"
                  @click="openWhatsApp(patient.cellphone)"
                  v-if="patient !== null && patient.cellphone"
                >
                  <WhatsAppIcon />
                  <span>
                    <p>{{ patient.cellphone }}</p>
                  </span>
                </a>
              </td>
              <td>
                <b-button variant="primary"
                @click.stop="goTo('/pacientes/' + patient.id)"
                >
                  Visualizar
                </b-button>
              </td>
              <td v-show="activePatientSelection" :key="reloadCheckbox">
                <Check
                  @change="selectPatient(patient)"
                  :value="
                    Boolean(
                      patientSelecteds.find(option => option.id === patient.id)
                    )
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
        <NoFilterResults
          v-else-if="!patients?.length"
          message="Os filtros aplicados não geraram nenhum resultado."
        />

        <div class="pagination-position pt-0">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="limit"
            first-number
            last-number
            size="sm"
          />
        </div>
      </div>
    </div>

    <FilterPatientModal @filterPatients="filterPatients" />
    <UnifyPatientsModal
      :patients="patientSelecteds"
      :removeSelectedPatient="removeSelectedPatient"
      @unify-patients="unifiedPatients"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { debounce } from 'lodash'
import {
  userHasPermission,
  getCurrentClinic
} from '@/utils/localStorageManager'

export default {
  name: 'PatientsList',
  metaInfo: {
    title: 'Eyecare - Lista de Pacientes'
  },
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Icon: () => import('@/components/General/Icon'),
    Check: () => import('@/components/General/Check'),
    Close: () => import('@/assets/icons/close.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    FilterBar: () => import('@/assets/icons/filter-bar.svg'),
    FilterPatientModal: () => import('@/components/Patient/FilterPatientModal'),
    UnifyPatientsModal: () =>
      import('@/components/Patient/UnifyPatientsModal.vue'),
    Loading: () => import('@/assets/icons/loading.svg'),
    WhatsAppIcon: () => import('@/assets/icons/whatsapp-icon.svg'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  created() {
    this.loadPatients(true)
    this.debounceGetPatients = debounce(this.loadPatients, 1200)
    this.debounceLoadOnEmpty = debounce(this.loadPatients, 800)
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      page: 1,
      count: 0,
      limit: 0,
      total: 0,
      letters: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ],
      selectedLetter: null,
      search: '',
      patients: [],
      isLoading: false,
      disableSearchInput: false,
      debounceLoadOnEmpty: null,
      debounceGetPatients: null,
      activePatientSelection: false,
      patientSelecteds: [],
      reloadCheckbox: 1,
      birthdayStart: null,
      birthdayEnd: null,
      patientStatus: '',
      periodSelectedString: '',
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  computed: {
    unificateButtonText: {
      get() {
        if (this.patientSelecteds?.length === 1)
          return 'Unificar paciente (1 selecionado)'
        else if (this.patientSelecteds?.length > 1)
          return (
            'Unificar pacientes (' +
            this.patientSelecteds?.length +
            ' selecionados)'
          )
        else return 'Unificar paciente'
      },
      set(val) {}
    }
  },
  methods: {
    moment,
    userHasPermission,
    selectLetter(letter) {
      this.selectedLetter !== letter
        ? (this.selectedLetter = letter)
        : (this.selectedLetter = null)
      this.search = ''
      this.loadPatients(true)
    },
    loadOnEmpty() {
      if(this.search.length === 0) {
        this.loadPatients(true)
      }
    },
    loadPatients(force) {
      force = force || false
      if (!this.isLoading) {
        if(this.selectedLetter === null && this.search.length === 0 && force === false) return

        this.isLoading = true
        this.disableSearchInput = true
        // it guarantees that the search field will be enabled in case of server slow responses
        const toId = setTimeout(() => (this.disableSearchInput = false)
        , 10000)
        if (this.search.length > 0) this.selectedLetter = null
        this.api
          .listPatients(
            this.page,
            this.clinic.id,
            this.search,
            this.selectedLetter,
            this.birthdayStart,
            this.birthdayEnd,
            this.patientStatus
          )
          .then(res => {
            this.patients = res.data.data
            this.count = res.data.total
            this.limit = res.data.per_page
            this.total = res.data.total
            this.reloadCheckbox++
          })
          .catch(err => {
            console.log('Err', err)
          })
          .finally(() => {
            this.isLoading = false
            this.disableSearchInput = false
            clearTimeout(toId)
          })
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
    maskedPhone(cellphone) {
      if (!cellphone) {
        return '-'
      }
      cellphone = cellphone.replaceAll(/\D/g, '')

      if (cellphone.length >= 10) {
        return cellphone.replace(/^(\d\d)(\d{4,5})(\d{4}).*/, '($1) $2-$3')
      }
      if (cellphone.length >= 5) {
        return cellphone.replace(/^(\d{4,5})(\d{4}).*/, '$1-$2')
      }
      return cellphone
    },
    selectPatient(patient) {
      if (this.patientSelecteds.find(option => option.id === patient.id))
        this.removeSelectedPatient(patient)
      else this.patientSelecteds.push(patient)
    },
    removeSelectedPatient(patient) {
      this.patientSelecteds.splice(this.patientSelecteds.indexOf(patient), 1)
    },
    openUnifyPatientModal() {
      this.$bvModal.show('unify-patients-modal')
    },
    unifiedPatients() {
      this.$toast.success('Paciente unificado com sucesso!')
      this.patientSelecteds = []
      this.reloadCheckbox++
      this.loadPatients(true)
    },
    openFilterModal() {
      this.$bvModal.show('filter-patient-modal')
    },
    filterPatients($event) {
      this.patientStatus = $event.patientStatus
      this.periodSelectedString = $event.periodSelectedString

      if (!$event.period) {
        this.birthdayStart = null
        this.birthdayEnd = null
      } else {
        this.birthdayStart = $event.period[0]
        this.birthdayEnd = $event.period[1]
      }

      this.loadPatients(true)
      this.$bvModal.hide('filter-patient-modal')
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      } else if (isNaN(parseInt(whatsappNumber))) {
        this.$toast.warning('Número de telefone inválido')
        return
      }

      window.open(
        `https://api.whatsapp.com/send?phone=55${whatsappNumber}`,
        '_blank'
      )
    },
    getBirthdayDate(birthday) {
      if (!birthday) return '-'
      return moment(birthday).format('DD/MM/YYYY')
    },

    getAge(birthday) {
      const days = moment().diff(moment(birthday), 'days')
      const months = moment().diff(moment(birthday), 'month')
      const years = moment().diff(moment(birthday), 'years')

      if (!birthday) {
        return '-'
      } else if (months > 1 && months < 12) {
        return `(${months} meses)`
      } else if (days <= 30) {
        return `(${days} dias)`
      } else {
        return `${years} anos`
      }
    },
  },
  watch: {
    page: function () {
      this.loadPatients(true)
    },
    openFilterModal() {
      this.$bvModal.show('filter-patient-modal')
    },
    filterPatients($event) {
      this.patientStatus = $event.patientStatus
      this.periodSelectedString = $event.periodSelectedString

      if (!$event.period) {
        this.birthdayStart = null
        this.birthdayEnd = null
      } else {
        this.birthdayStart = $event.period[0]
        this.birthdayEnd = $event.period[1]
      }

      this.loadPatients()
      this.$bvModal.hide('filter-patient-modal')
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, '')

      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      } else if (isNaN(parseInt(whatsappNumber))) {
        this.$toast.warning('Número de telefone inválido')
        return
      }

      window.open(
        `https://api.whatsapp.com/send?phone=55${whatsappNumber}`,
        '_blank'
      )
    },
    selectedLetter: function () {
      this.loadPatients()
    },
    activePatientSelection: function () {
      this.patientSelecteds = []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.patients-list {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .table-button {
    background: #305bf2;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    &:hover {
      background: #224bdf;
    }
  }

  .header {
    margin-bottom: 24px;

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 0;
    }

    .quantity {
      font-weight: 600;
      font-size: 12px;
      color: var(--type-active);
      margin-left: 20px;
      margin-bottom: 5px;
    }
  }

  .list-container {
    width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;

    .list-header {
      padding: 16px;
      border-bottom: 2px solid var(--neutral-200);
      align-items: center;

      .input-container {
        width: 305px;
        position: relative;

        .input-icon {
          width: 24px;
          height: 24px;
          stroke: var(--neutral-500);
          margin-right: 8px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }

        .form-control {
          padding: 11px 16px 11px 48px;
        }
      }

      .filter__container {
        position: relative;
        background: transparent;
        border: 1px solid var(--neutral-300);
      }

      .filter-letter__container {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;

        li {
          font-weight: 600;
          font-size: 16px;
          color: var(--type-active);
          position: relative;
          width: 24px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 12px;

          .removeFilter {
            position: absolute;
            z-index: 1;
            padding: 1.5px;
            margin-left: 30px;
            margin-bottom: 30px;
            border-radius: 50%;
            background-color: var(--states-error-soft-red);
            transition: 0.2s;
            border: 1.5px solid #fff;
            .close {
              width: 18px;
              fill: var(--states-error);
              transition: 0.2s;
            }
          }

          &.active {
            color: var(--neutral-000);
            background-color: var(--orange);

            &:hover {
              color: var(--neutral-000);
              background-color: var(--orange);
            }
          }

          &:hover {
            background-color: var(--neutral-100);
          }
        }
      }
    }

    .list-body {
      height: calc(100% - 74px);

      .table {
        width: 100%;
        display: table;
        padding-left: 24px;

        thead {
          th {
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-blue);
            border-top: 0;
            padding: 16px 0;
            border-bottom: 1px solid var(--neutral-300);

            &:first-child {
              padding-left: 24px;
            }
          }
        }

        tbody {
          tr {
            padding: 0 24px;
            border-bottom: 1px solid var(--neutral-200);

            &:last-child {
              border-bottom: 0;
            }

            &:hover {
              background-color: var(--neutral-100);
            }
          }

          td {
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);
            border-top: 1px solid var(--neutral-200);
            padding: 16px 0;
            vertical-align: middle;

            &:first-child {
              padding-left: 24px;
            }

            &:last-child {
              padding-right: 24px;
            }

            .avatar {
              width: 40px;
              height: 40px;
              margin-right: 16px;
              border-radius: 50%;
            }

            .more-icon {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-500);
            }

            .whatsapp-link {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 6px;
              text-decoration: underline;
              cursor: pointer;

              span {
                font-weight: 700;

                p {
                  color: var(--blue-500);
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 700;
                  text-decoration: none;
                }
              }

              svg {
                height: 28px;
                width: 28px;
              }
            }
          }
        }
      }
    }
  }
  .inactive {
    color: #8696ac;
  }
  .inactive-label {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .ml2 {
    margin-left: 2px;
  }
  .divisor {
    margin: 0 5px;
    color: #d9dff2;
    @media screen and (max-width: 1280px) {
      color: white;
    }
  }
}
</style>
